<div class="layout-content">
    <div id="overlay" [ngStyle]="{ display : loader }">
        <div class="spinner"></div>
        <br />
        Loading...
    </div>
    <!-- Content -->
    <div class="container flex-grow-1 container-p-y">

        <!-- Header -->
        <div class="container-m-nx container-m-ny theme-bg-white mb-4 marginTop">
            <div class="media col-md-10 col-lg-8 col-xl-7 py-5 mx-auto">
                <img src="../../assets/images/logo.jpg" alt="" class="d-block ui-w-100 rounded-circle">
                <div class="media-body ml-5">
                    <h4 class="font-weight-bold mb-4">Paiement en ligne</h4>
                </div>
                <button class="btn btn-primary ml-5" (click)="onLogout()"><span class="fa fa-sign-out-alt fa-lg"></span>
                    Déconnecter</button>
            </div>

            <hr class="m-0">
        </div>
        <!-- Header -->

        <div class="row">
            <div class="col">

                <!-- Info -->
                <div class="card mb-4">
                    <div class="card-body">
                        <div *ngIf="!statu" class="alert alert-danger" role="alert">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            <span *ngIf=" (orderStatu?.params | json) != ({} | json); else paramsNull ">{{orderStatu?.params.respCode_desc}}</span>
                            <ng-template #paramsNull>
                                <span>  {{orderStatu?.actionCodeDescription}}</span>
                            </ng-template>
                            <span>  Votre paiement a été refusé</span>
                        </div>

                        <div *ngIf="statu" class="alert alert-success" role="alert">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <span>  {{orderStatu?.params.respCode_desc}}</span>
                            <span>  Votre paiement a été accepté</span>
                        </div>

                        <table class="table table-bordered" *ngIf="orderStatu">
                            <tbody>
                                <tr>
                                    <th scope="row">Identifiant de la transaction</th>
                                    <!--<td *ngIf="dataFromLS.modeDahabeya">{{orderStatu.attributes[0].value}}</td>-->
                                    <td>{{orderId}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Numéro de commande</th>
                                    <!--<td *ngIf="dataFromLS.modeDahabeya">{{orderStatu.orderNumber}}</td>-->
                                    <td>{{orderStatu.OrderNumber}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Numéro d’autorisation</th>
                                    <!--<td *ngIf="dataFromLS.modeDahabeya">{{orderStatu.cardAuthInfo.approvalCode}}</td>-->
                                    <td>{{orderStatu.approvalCode}}</td>

                                </tr>
                                <tr>
                                    <th scope="row">Date et heure de la transaction</th>
                                    <!--<td *ngIf="dataFromLS.modeDahabeya">{{orderStatu.date | date:'short'}}</td>-->
                                    <td>{{currentDate | date:'short'}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Montant de paiement</th>
                                   <!-- <td *ngIf="dataFromLS.modeDahabeya">{{orderStatu.amount/100}}</td>-->
                                    <td>{{orderStatu.Amount/100}}</td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <!-- <div *ngIf="statu" class="card border-success" style="margin-bottom: 25px;">
                            <div class="card-header bg-success">
                                Header
                            </div>
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">Status</div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center">
                                        {{orderStatu.orderStatusDescription}}</div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <form>
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                    <label>Montant réglé :</label>
                                    <input type="text" class="form-control form-control-success" placeholder=""
                                        [(ngModel)]="montantRegle" name="montantRegle"
                                        style="background-color: #FFFFFF;font-weight: bold; text-align: right; color: #000000;">
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <button class="btn btn-danger ml-5" (click)="onLogout()">Envoyer</button>
                                </div>
                            </div>
                        </form> -->

                    </div>

                </div>
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row justify-content-content-center" *ngIf="messageEmail">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 offset-md-2">
                                <h5>{{messageEmail}}</h5>
                            </div>
                        </div>
                        <div class="row justify-content-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-3 offset-md-2">
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-success" [(ngModel)] ="emailClient"
                                        placeholder="name@example.com">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-3">
                                <div class="input-group">

                                    <button class="btn btn-primary" type="button" (click)="sendMail()"><i
                                            class="fa far fa-envelope fa-lg"></i>
                                        Envoyer par mail</button>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-3 offset-md-2">
                                <label> </label>
                                <button class="btn btn-primary" type="button" (click)="telechargerQuittance()"><i
                                        class="fa fa-download fa-lg"></i>
                                    Télécharger</button>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-3">
                                <label> </label>
                                <button class="btn btn-primary" type="button" (click)="imprimerQuittance()"><i
                                        class="fa far fa-save fa-lg"></i>
                                    Imprimer</button>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 pb-3">
                                <label> </label>
                                <button class="btn btn-primary" type="button" (click)="returnAcceuil()"><i
                                        class="fa far fa-home fa-lg"></i>
                                    Retour Accueil</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- / Content -->
            <div class="modal fade" id="modalYT" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content" style="width: 1000px; height: 629px ;">
                        <div class="modal-body mb-0 p-0">
                            <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                <iframe #myiFrame class="embed-responsive-item" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-center">
                            <span class="mr-4"> </span>
                            <button type="button" class="btn btn-outline-primary btn-danger"
                                data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3" style="text-align: center;">
            <h5>Copyright &copy; {{year}} OPGI. Powered by <a href="https://www.idweb-dz.com/" target="_blank" style="
            font-size: 20px !important;">IDWEB</a>
            </h5>
        </div>
    </div>
</div>