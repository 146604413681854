import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommenService } from '../commen.service';
import { OrderStatusDahabya } from '../models/orderStatusDahabya.model';

import Swal from 'sweetalert2'
import { ObjPaiement } from '../models/objPaiement.model';
import { CryptoJsService } from '../crypto-service';
import { OrderStatusCIB } from '../models/orderStatusCIB';
@Component({
  selector: 'app-payement-effectue',
  templateUrl: './payement-effectue.component.html',
  styleUrls: ['./payement-effectue.component.css']
})
export class PayementEffectueComponent implements OnInit {
  @ViewChild('myiFrame', { static: true }) myiFrame: ElementRef;
  orderId = "";
  loader: string = 'none';
  montantRegle = "";
  orderStatu: any;
  orderStatuCIB: OrderStatusCIB;
  statu: boolean;
  fileURL: any;
  fichier: any;
  emailClient: any;
  messageEmail: string = null;
  dataFromLS: ObjPaiement;
  currentDate = new Date();
  year = new Date().getFullYear();
  constructor(private _commenService: CommenService, private activatedRoute: ActivatedRoute, private _route: Router, private _cryptoService: CryptoJsService) {
  }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    if (this.orderId != null || this.orderId != "") {
      this.loader = 'inline-block';
      this.dataFromLS = this._cryptoService.decrypt(localStorage.getItem('objPaiement'));
      this._commenService.checkOperationDahabeya(this.orderId).subscribe(res => {
        console.log("res : ", res);
        var errorCode, orderStatus;
        /*if (this.dataFromLS.modeDahabeya) {
          this.orderStatu = <OrderStatusDahabya>res;
          this.dataFromLS.identifiantTran = this.orderStatu.attributes[0].value;
          this.dataFromLS.numeroCommande = this.orderStatu.orderNumber;
          this.dataFromLS.numeroAutorisation = this.orderStatu.cardAuthInfo.approvalCode;
          this.dataFromLS.dateTransaction = this.orderStatu.date;
          errorCode = this.orderStatu.errorCode;
          orderStatus = this.orderStatu.orderStatus
        } else {*/
          this.orderStatu = <OrderStatusCIB>res;
          this.dataFromLS.identifiantTran = this.orderId
          this.dataFromLS.numeroCommande = this.orderStatu.OrderNumber;
          this.dataFromLS.numeroAutorisation = this.orderStatu.approvalCode;
          this.dataFromLS.dateTransaction = Date.now();
          errorCode = this.orderStatu.ErrorCode;
          orderStatus = this.orderStatu.OrderStatus
          console.log("tesssssst : ",this.orderStatu.actionCodeDescription)
          console.log("respCode : ",this.orderStatu.params)
       // }
        
        if (errorCode == "0") {
          console.log("ErrorCode 0");
          if (orderStatus == 2) {
            console.log("OrderStatus 2");


            if (!this.dataFromLS) {
              this.loader = 'none';
              return
            }


            this.callSauvegarder();

          } else {
            console.log("OrderStatus !2");
            this.loader = 'none';
            this.statu = false;
            console.log("loader !2", this.loader);
          }
        } else {
          console.log("ErrorCode !0");
          this.loader = 'none';
          this.statu = false;
        }
      }, err => {
        this.loader = 'none';
        this.statu = false;
      });
    }
  }

  /*ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    if (this.orderId != null || this.orderId != "") {
      this.loader = 'inline-block';
      this.dataFromLS = this._cryptoService.decrypt(localStorage.getItem('objPaiement'));
      this._commenService.checkOperationDahabeya(this.orderId, this.dataFromLS.modeDahabeya).subscribe(res => {
        console.log("res : ", res);
        var errorCode, orderStatus;
        if (this.dataFromLS.modeDahabeya) {
          this.orderStatu = <OrderStatusDahabya>res;
          this.dataFromLS.identifiantTran = this.orderStatu.attributes[0].value;
          this.dataFromLS.numeroCommande = this.orderStatu.orderNumber;
          this.dataFromLS.numeroAutorisation = this.orderStatu.cardAuthInfo.approvalCode;
          this.dataFromLS.dateTransaction = this.orderStatu.date;
          errorCode = this.orderStatu.errorCode;
          orderStatus = this.orderStatu.orderStatus
        } else {
          this.orderStatu = <OrderStatusCIB>res;
          this.dataFromLS.identifiantTran = this.orderId
          this.dataFromLS.numeroCommande = this.orderStatu.OrderNumber;
          this.dataFromLS.numeroAutorisation = this.orderStatu.approvalCode;
          this.dataFromLS.dateTransaction = Date.now();
          errorCode = this.orderStatu.ErrorCode;
          orderStatus = this.orderStatu.OrderStatus
          console.log("tesssssst : ",this.orderStatu.actionCodeDescription)
          console.log("respCode : ",this.orderStatu.params)
        }
        
        if (errorCode == "0") {
          console.log("ErrorCode 0");
          if (orderStatus == 2) {
            console.log("OrderStatus 2");


            if (!this.dataFromLS) {
              this.loader = 'none';
              return
            }


            this.callSauvegarder();

          } else {
            console.log("OrderStatus !2");
            this.loader = 'none';
            this.statu = false;
            console.log("loader !2", this.loader);
          }
        } else {
          console.log("ErrorCode !0");
          this.loader = 'none';
          this.statu = false;
        }
      }, err => {
        this.loader = 'none';
        this.statu = false;
      });
    }
  }*/

  /*callReverse() {
    var amount = (this.dataFromLS.modeDahabeya) ? this.orderStatu.amount : this.orderStatu.Amount
    console.log('reversed : ',amount)
    this._commenService.reverseOperationDahabeya(this.orderId, amount,this.dataFromLS.modeDahabeya).subscribe(resp => {
      console.log("reversed :", resp);
      this.loader = 'none';
    }, error => {
      console.log("reversed err:", error);
      this.loader = 'none';
    })
  }*/

    callReverse() {
      var amount = this.orderStatu.Amount;
      console.log('reversed : ',amount)
      this._commenService.reverseOperationDahabeya(this.orderId, amount).subscribe(resp => {
        console.log("reversed :", resp);
        this.loader = 'none';
      }, error => {
        console.log("reversed err:", error);
        this.loader = 'none';
      })
    }

  onLogout() {
    this._commenService.logout();
  }

  imprimerQuittance() {
    this.myiFrame.nativeElement.contentWindow.print();
  }

  telechargerQuittance() {
    window.open(
      this.fileURL,
      '_blank' 
    );
  }

  returnAcceuil() {
    this._route.navigate(['PaiementConstatation']);
  }

  sendMail() {
    console.log("email : ", this.dataFromLS);
    this.messageEmail = null;
    this._commenService.sendQuittanceMail(this.emailClient, this.dataFromLS).subscribe(data => {
      console.log(data);
      this.messageEmail = "message envoyé avec succès";
    }, err => {
      this.messageEmail = "message non envoyé";
    })
  }

  callSauvegarder() {
    this._commenService.sauvegarderPaiement(this.dataFromLS).subscribe(response => {
      console.log("sauvegarderPaiement :", response);
      this.loader = 'none';
      this.statu = true;
      localStorage.removeItem('objPaiement');
      const file = new Blob(
        [response], {
        type: 'application/pdf'
      });
      var fichier = file;
      this.fileURL = URL.createObjectURL(file);
      console.log('tesssst url', this.fileURL);
      console.log('html', this.myiFrame);
      this.myiFrame.nativeElement.setAttribute("src", this.fileURL);
      console.log('tesssst att setted');

    }, err => {
      console.log("sauvegarderPaiement err:", err);
      localStorage.removeItem('objPaiement');
      this.statu = false;
      this.callReverse();
    })
  }

}
