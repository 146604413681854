export class ObjPaiement {
    public identifiantTran: string;
    public numeroCommande: string;
    public numeroAutorisation: string;
    public dateTransaction: number;

    constructor(
        public idCaisse: number,
        public modePaiment: string,
        public objetPaiment: string,
        public numCheque: string,
        public idConstatation: number,
        public nbrmMois: number,
        public timber: number,
        public idLogin: number,
        public idPatrimoine: number,
        public fraisMisedemeure: number,
       // public modeDahabeya: boolean,
    ) { }
}